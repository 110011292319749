import React, { useState } from 'react';
import { motion } from 'framer-motion';
import introVid from './adventureChick.mp4';  // First video
import secondVid from './adventureChick2.mp4';  // Second video
import thirdVid from './adventure3.mp4';  // Third video
import './VideoContainer.css';  // Import your CSS file
import Overlay from './Overlay';  // Overlay component

const videos = [introVid, secondVid, thirdVid];  // Array of video sources

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1, scale: 1 },
  out: { opacity: 0, scale: 0.8 },
};

const pageTransition = {
  duration: 0.8,
  ease: "easeInOut",
};

const VideoContainer = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const handleOverlayClick = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  return (
    <div className="video-container">
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        key={currentVideoIndex} // Ensure video changes on index change
      >
        <video
          src={videos[currentVideoIndex]}
          alt="Video"
          autoPlay
          loop
          muted
          playsInline
          preload="metadata"
          className="responsive-video"
        >
          Your browser does not support the video tag.
        </video>
      </motion.div>

      {/* Pass the current index as the `type` prop */}
      <Overlay type={currentVideoIndex} onClick={handleOverlayClick} />
    </div>
  );
};

export default VideoContainer;
