import React, { useState, useEffect } from 'react';
import './Overlay.css';
import { ArrowBigRight } from 'lucide-react';

const Overlay = ({ type, onClick }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Simulating a 2-second loading time

    return () => clearTimeout(timer);
  }, []);

  const handleOverlayClick = () => {
    setIsClicked(true);
    onClick(); // Trigger the parent click event for the page transition
  };

  return (
    <div className="video-overlay" onClick={handleOverlayClick}>
     
        <div className="overlay-content">
          {type === 0 && (
            <div>
            <p className="tagline">Explore</p>
            <div className="features">
              <div className="feature">
                <span>
                  <ArrowBigRight size={48} />
                </span>
              </div>
            </div>
            </div>
          )}
{type === 1 && (
  <div>
  <p className="tagline">Welcome to Adventure Labs</p>
  <div className="features">
    <div className="feature">
    <p>Embark on a creative journey, where AI transforms your imagination into reality.</p>
      <span>
        <ArrowBigRight size={48} />
      </span>
    </div>
  </div>
  </div>
   
)}
          {type === 2 && (
            <div className="contact-info">
              <h1>Contact</h1>
              <p>Email: business.adventure.123@gmail.com</p>
              
            </div>
          )}
        </div>
      
    </div>
  );
};

export default Overlay;
