import React, { useState, useEffect } from 'react';
import cartPic from './shoppingCart.png';
import './Header.css';
import menuIcon from './menu.png';

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check screen width and set isMobile state
    const checkScreenWidth = () => {
      if (window.innerWidth < 1000) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check
    checkScreenWidth();

    // Add event listener to resize event
    window.addEventListener('resize', checkScreenWidth);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  return (
    <div className="mainContainer">
      <div className="navContainer">
        {/* {isMobile ? (
          <div className="menuIconContainer">
            <img src={menuIcon} alt="Menu Icon" className="menuIcon" />
          </div>
        ) : (
          <nav className="navBar">
            <li><a href='/'>Art</a></li>
            <li><a href='/'>About</a></li>
            <li><a href='/'>Contact</a></li>
            <li><a href='/'>Shop</a></li>
          </nav>
        )} */}
        <h3 className="centeredTitle">
          Adventure Labs
        </h3>
        {/* <div className="cartContainer">
          <img src={cartPic} alt="shoppingCartPicture" className="shoppingCart" />
        </div> */}
      </div>
    </div>
  );
};

export default Header;
